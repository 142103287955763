import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.object.assign";
import React from 'react';
import { SafeUnpublishedPage } from 'gatsby-theme-ww-prismic/src/pages/unpublishedPreview';
import PageTemplate from 'src/templates/page';
import ProjectTemplate from 'src/templates/project';
export var UnpublishedPreview = function UnpublishedPreview(props) {
  var templateMap = {
    page: PageTemplate,
    project: ProjectTemplate
  };
  return React.createElement(SafeUnpublishedPage, Object.assign({
    templateMap: templateMap
  }, props));
};
export default UnpublishedPreview;